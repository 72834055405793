// components/Contact.js
import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Contact Me</h1>
      <p>Add your contact form or contact information here</p>
    </div>
  );
}

export default Contact;
