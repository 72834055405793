// components/Projects.js
import React from 'react';
import projectsData from '../data/projectsData';

function Projects() {
  return (
    <div className='project-header'>
      <h1>Utvalda projekt</h1>
      <div className="project-cards">
        {projectsData.map((project) => (
          <div className="project-card" key={project.title}>
            <img src={project.icon} alt={project.title + " logo"} />
            <h2>{project.title}</h2>
            <p>{project.description}</p>
            {/* Display development program icons */}
            <div className="development-program-icons">
              {project.developmentProgram.map((iconPath, index) => (
                <img key={index} src={iconPath} alt="Development Program Icon" className="development-program-icon" />
              ))}
            </div>
            <a href={project.websiteURL}>Visit Website</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
