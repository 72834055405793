const projectsData = [
  {
    title: 'Space Invader',
    icon: 'space-invader.png', // Use forward slashes for paths
    description: 'Spel som använder rörelsetangenterna',
    websiteURL: 'https://js-invader.vercel.app/',
    developmentProgram: ['JS.png'] // Array of strings
  },
  {
    title: 'Project 2',
    icon: './public/logo192.png', // Use forward slashes for paths
    description: 'Description of project 2',
    websiteURL: 'https://www.project2.com',
    developmentProgram: ['Vue.png', './public/logo192.png', './public/logo192.png'] // Array of strings
  },
  {
    title: 'F1 Map',
    icon: 'f1-flag.png', // Use forward slashes for paths
    description: 'Description of project 2',
    websiteURL: '  https://vercel.com/olsson7/f1-map',
    developmentProgram: ['Vue.png', './public/logo192.png', './public/logo192.png'] // Array of strings
  },
  
  


  // Add more projects as needed
];

export default projectsData;
