// components/Home.js
import React from 'react';
import ParticlesBackground from './particlesbackground';
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css"
import Projects from './Projects';


function Home() {
  return (





    <div>

      <section>
        <div id="container">
          <ParticlesBackground />
          <div id="your-div">
            <h1>Systemutvecklare</h1>
            <div class="col text-center">
              <a href="#about" class="btn btn-outline-light text-decoration-none" role="button">Link Button</a>

            </div>


          </div>
        </div>
      </section>



      <section id="about">
        <div id="container">

          <div id="your-div1">
            <h1>asdasdasd</h1>
            <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus vel massa eget venenatis. Etiam non ante velit. Cras sollicitudin metus ac ipsum blandit maximus. Fusce ac pharetra libero. Ut maximus velit ut neque suscipit, a fringilla erat condimentum. Suspendisse hendrerit at ipsum vel tempus. Nullam ultrices dignissim libero, non sodales est suscipit sed. Morbi molestie enim est, at auctor mauris bibendum vitae. Cras erat mauris, molestie ut quam in, suscipit consequat mi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse non pellentesque nunc.</h1>


          </div>
        </div>
      </section>


      <section id="more">
        <div id="container">

          <div id="your-div1">



          </div>
        </div>
      </section>


      <section id="more">

        <Projects />
                 <div id="container">

          <div id="your-div2">
            <h1>Utvalda projekt</h1>


            <div class="col text-center">
            <a href="https://dawn-sky-1548.on.fleek.co/" target="_blank">spaceinvader</a>

            </div>


            <div class="col text-center">
            <a href="  https://f1-map.vercel.app/" target="_blank">F1Map</a>

            </div>

          </div>
        </div>
      </section>


    </div>
  );
}

export default Home;
