import React from "react"
import Particles from "react-tsparticles"
import particlesConfig from "./config/particles-config"
import { loadFull } from "tsparticles"


function ParticlesBackground() {
    async function loadParticles(main){
        await loadFull(main)

    }



    return (
        <Particles 
        id="tsparticles"
        init={loadParticles}
        options={particlesConfig}
        

        />
    )
}

export default ParticlesBackground