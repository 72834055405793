// components/About.js
import React from 'react';

function About() {
  return (
    <div>
      <h1>About Me</h1>
      <p>Information about yourself, your experience, and interests</p>
    </div>
  );
}

export default About;
